const yAxisProps = {
  type: "linear",
  display: false,
  title: {
    display: false,
  },
  ticks: {
    display: false,
  },
  grid: {
    drawTicks: false,
  },
};

const xAxisProps = {
  ticks: {
    fontSize: 6,
    display: false,
  },
  grid: {
    drawTicks: false,
  },
};

const xAxisLargeProps = {
  ticks: {
    padding: 20,
    font: {
      size: 12.5,
      family: "Rubik",
      weight: 400,
    },
    autoSkip: false,
    maxRotation: 90,
    minRotation: 55,
  },
  grid: {
    drawTicks: false,
  },
};

let formatter = Intl.NumberFormat("en", { notation: "compact" });

const tooltipLabels = {
  callbacks: {
    label: (context) => {
      let label = "";
      if (context.dataset.label === "RIAA") {
        label = "RIAA: " + context.parsed.y + "x Platinum";
        return label;
      } else {
        return (
          `${context.dataset.label}: ` + formatter.format(context.parsed.y)
        );
      }
    },
  },
};

export const miniCompareChartOptions = (artist, maxData) => {
  // What this is doing is:
  // - Setting the max of the y-axis to max plus a little bit.
  // - The reason why, is its will get cut off if it doesnt had the top padding.

  const maxValue = (max) => {
    return max + max * 0.05;
  };

  return {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      tooltip: tooltipLabels,
      title: {
        display: false,
        font: {
          size: 20,
        },
        text: artist.name,
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: { ...xAxisProps },
      yAxis1: {
        ticks: {
          display: false,
        },
        max: maxValue(maxData.riaaMax),
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: true,
        },
      },
      yAxis2: {
        ...yAxisProps,
        min: 1000,
        max: maxData.firstWeekSalesMax && maxValue(maxData.firstWeekSalesMax),
      },
      yAxis3: {
        ...yAxisProps,
        min: 1000,
        max: maxData.streamsMax && maxValue(maxData.streamsMax),
      },
    },
  };
};

export const miniChartOptions = (artist) => {
  return {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      tooltip: tooltipLabels,
      title: {
        display: false,
        font: {
          size: 26,
          family: "Rubik",
          weight: 500,
        },
        text: artist.name,
        color: "rgb(66,66,66)",
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: { ...xAxisProps },
      yAxis1: {
        ticks: {
          display: false,
        },
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: true,
        },
      },
      yAxis2: {
        ...yAxisProps,
      },
      yAxis3: {
        min: 50000000,
        max: 800000000,
        ...yAxisProps,
      },
    },
  };
};

export const largeChartOptions = (artist) => {
  return {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      tooltip: tooltipLabels,
      title: {
        display: false,
      },
    },
    scales: {
      x: { ...xAxisLargeProps },
      yAxis1: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "RIAA",
          font: {
            size: 15,
            family: "Roboto",
          },
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        min: 0,
      },
      yAxis2: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "First Week Sales",
          font: {
            size: 15,
            family: "Roboto",
          },
        },
        grid: {
          drawTicks: false,
        },
      },
      yAxis3: {
        type: "linear",
        display: false,
        title: {
          display: false,
          text: "Streams",
          font: {
            size: 15,
            family: "Roboto",
          },
        },
        position: "left",
      },
    },
  };
};

const datasetProps = {
  pointRadius: 0.4,
  pointHoverRadius: 5, // Point hoving size
  pointHitRadius: 10,
  tension: 0.2, // Line sharpness
  borderWidth: 6, // Line thickness
  pointBorderWidth: 0,
};

export const getChartData = (
  projectLabels,
  riaaData,
  firstWeekSalesData,
  streamsData
) => {
  return {
    labels: projectLabels.label,
    datasets: [
      {
        label: "RIAA",
        data: riaaData.data,
        backgroundColor: "#047EFD", // Points color
        borderColor: "#047EFD", // Line color
        yAxisID: "yAxis1",
        ...datasetProps,
      },
      {
        label: "First Week Sales",
        data: firstWeekSalesData.data,
        backgroundColor: "#FCDD0B",
        borderColor: "#FCDD0B",
        yAxisID: "yAxis2",
        ...datasetProps,
      },
      {
        label: "First Week Streams",
        data: streamsData.data,
        backgroundColor: "#02CD46",
        borderColor: "#02CD46",
        yAxisID: "yAxis3",
        ...datasetProps,
      },
    ],
  };
};

export const singleChartOptions = (artist, displayLabels, maxRiaa) => {
  return {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      tooltip: tooltipLabels,
      title: {
        display: false,
        font: {
          size: 26,
          family: "Rubik",
          weight: 500,
        },
        text: artist.name,
        color: "rgb(66,66,66)",
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          color: "rgb(66,66,66)",
          beginAtZero: true,
          display: displayLabels,
        },
        grid: {
          drawTicks: false,
        },
        max: maxRiaa ?? null,
      },
      x: {
        ticks: {
          color: "rgb(66,66,66)",
          beginAtZero: true,
          display: displayLabels,
        },
        grid: {
          drawTicks: false,
        },
      },
    },
  };
};
