import React from "react";
import MetricGuide from "./MetricGuide";
import { Typography } from "@mui/material";

const Metrics = () => {
  return (
    <div style={{ color: "#424242", padding: "100px 0" }}>
      <Typography
        variant="h3"
        style={{
          fontSize: "calc(100% + 1.4vw)",
          fontWeight: 400,
        }}
      >
        The Most Valuable Measurements
      </Typography>
      <MetricGuide />
    </div>
  );
};

export default Metrics;
