export default function updateSearch(artists, query) {
  let tempList = [...artists];
  let searchQueryLength = query.length;

  tempList.sort((a, b) => b.riaaTotal - a.riaaTotal);

  if (query.length) {
    tempList = tempList.filter(
      (artist) =>
        artist.name.slice(0, searchQueryLength).toLowerCase() ===
        query.toLowerCase()
    );
  }

  return tempList;
}
