import React, { useState } from "react";
import { HeaderIcons } from "./SmallProjectChart/HeaderIcons";
import { LargeChartModal } from "./LargeProjectChart/LargeChartModal";
import { Card } from "@mui/material";
import ProjectsChart from "./SmallProjectChart/SmallChart";
import { getAlbumDates, getSingleDates } from "../../utils/getAlbumDates";
import { selectComparedArtists } from "../../redux";
import { useSelector } from "react-redux";
import { Chart as SinglesChart } from "./SmallSingleChart/Chart";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import "./charts.css";

const ChartCard = ({ artist, maxData, type }) => {
  const [displayProjectsChart, setDisplayProjectsChart] = useState(
    type === undefined || type === "album"
  );
  const [largeChartIsOpen, setLargeChartIsOpen] = useState(false);
  const comparedArtists = useSelector(selectComparedArtists);
  const { firstAlbumYear, lastAlbumYear } = getAlbumDates(
    artist.projects,
    true
  );
  const { firstSingleYear, lastSingleYear } = getSingleDates(artist.singles);
  const location = useLocation();
  const isSelected = Boolean(
    comparedArtists.find(({ name }) => name === artist.name) &&
      location.pathname === "/Charts"
  );

  return (
    <Card
      sx={{ boxShadow: 2 }}
      className={classNames("miniChartContainer", { selected: isSelected })}
    >
      <div>
        <HeaderIcons
          setLargeChartIsOpen={() => setLargeChartIsOpen(true)}
          artist={artist}
          displayProjectsChart={displayProjectsChart}
          setDisplayProjectsChart={setDisplayProjectsChart}
          hideSingleAlbumToggle={Boolean(type)}
        />
        {displayProjectsChart && (
          <ProjectsChart artist={artist} maxData={maxData} />
        )}
        {!displayProjectsChart && (
          <SinglesChart
            artist={artist}
            displayLabels={false}
            maxRiaa={maxData?.singleRiaaMax}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "18px",
            padding: "5px 12px 0",
          }}
        >
          <div>{displayProjectsChart ? firstAlbumYear : firstSingleYear}</div>
          <div>{displayProjectsChart ? lastAlbumYear : lastSingleYear}</div>
        </div>
      </div>
      <LargeChartModal
        data={{ setLargeChartIsOpen, largeChartIsOpen, artist }}
      />
    </Card>
  );
};

export default ChartCard;
