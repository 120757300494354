import React, { useState } from "react";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestShareButton,
  PinterestIcon,
  RedditIcon,
  RedditShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { Box, Input, Alert } from "@mui/material";
import { copyLink } from "../../utils/svgs";
import "./styles.css";

const Share = ({ customUrl }) => {
  const [copied, setCopied] = useState(false);
  const url = window.location.href;

  const shareUrl = customUrl ?? url;
  const iconSize = 40;

  const copy = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setCopied(true);
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Input className="input" value={shareUrl} />

        <span
          onClick={() => copy()}
          className={copied ? "copiedLink" : "copyLink"}
        >
          {copyLink}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: 20,
        }}
      >
        <FacebookShareButton
          url={shareUrl}
          quote={"Title"}
          hashtag={"Portfolio"}
        >
          <FacebookIcon
            className="my-social-icon"
            size={iconSize}
            round={true}
          />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          quote={"Title"}
          hashtag={"Portfolio"}
        >
          <TwitterIcon
            className="my-social-icon"
            size={iconSize}
            round={true}
          />
        </TwitterShareButton>

        <RedditShareButton url={shareUrl} quote={"Title"} hashtag={"Portfolio"}>
          <RedditIcon className="my-social-icon" size={iconSize} round={true} />
        </RedditShareButton>
        <PinterestShareButton
          url={shareUrl}
          quote={"Title"}
          hashtag={"Portfolio"}
        >
          <PinterestIcon
            className="my-social-icon"
            size={iconSize}
            round={true}
          />
        </PinterestShareButton>
        <EmailShareButton url={shareUrl} quote={"Title"} hashtag={"Portfolio"}>
          <EmailIcon className="my-social-icon" size={iconSize} round={true} />
        </EmailShareButton>

        <FacebookMessengerShareButton
          url={shareUrl}
          quote={"Title"}
          hashtag={"Portfolio"}
        >
          <FacebookMessengerIcon
            className="my-social-icon"
            size={iconSize}
            round={true}
          />
        </FacebookMessengerShareButton>

        <LinkedinShareButton
          url={shareUrl}
          quote={"Title"}
          hashtag={"Portfolio"}
        >
          <LinkedinIcon
            className="my-social-icon"
            size={iconSize}
            round={true}
          />
        </LinkedinShareButton>
      </div>
      <div>
        {copied && <Alert severity="success">Copied to Clipboard!</Alert>}
      </div>
    </Box>
  );
};

export default Share;
