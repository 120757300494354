import React, { useEffect, useState } from "react";
import ChartCard from "../Charts/ChartCard";
import { useDispatch, useSelector } from "react-redux";
import {
  addArtistToCompare,
  selectComparedArtists,
  selectLoading,
  updateLoading,
} from "../../redux";
import SharePopover from "../../shared/Share/SharePopover";
import { useNavigate, useParams } from "react-router-dom";
import { clientUrl } from "../../utils/literals";
import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import Leaders from "./Leaders";
import axios from "axios";
import { API } from "../../utils/literals";
import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";
import { Helmet } from "react-helmet-async";
import "../Charts/charts.css";

export default function ComparedArtists() {
  const [maxData, setMaxData] = useState({});
  const comparedArtists = useSelector(selectComparedArtists);
  const loading = useSelector(selectLoading);
  const artistsWithSingles = comparedArtists
    .filter(({ singles }) => singles.length)
    .sort((a, b) => b.singlesStats.totals.riaa - a.singlesStats.totals.riaa);

  const removeStreams =
    comparedArtists.filter(
      (artist) => artist.studioAlbumStats.totals.firstWeekStreams > 0
    ).length <= 1 && comparedArtists.length > 1;
  const removeSales =
    comparedArtists.filter(
      (artist) => artist.studioAlbumStats.totals.firstWeekSales > 0
    ).length <= 1 && comparedArtists.length > 1;

  const artistsWithFilteredSalesAndStreams = comparedArtists.map((artist) => ({
    ...artist,
    projects: artist.projects.map((project) => ({
      ...project,
      streams: removeStreams ? null : project.streams,
      first_week_sales: removeSales ? null : project.first_week_sales,
    })),
  }));

  const { id } = useParams();
  const dispatch = useDispatch();

  const shareArtistString = () => {
    let names = [];
    comparedArtists.forEach((artist) => {
      let currName = artist.name;
      if (artist.name.includes(" ")) {
        currName = artist.name.replace(" ", "%20");
      }
      names.push(currName);
    });
    return clientUrl + "/SharedArtistComparison/" + names.join("&");
  };

  const getMaxes = () => {
    const temp = [...comparedArtists];
    let riaaMax = temp.sort(
      (a, b) =>
        b.studioAlbumStats.maxes.riaa.number -
        a.studioAlbumStats.maxes.riaa.number
    )[0].studioAlbumStats.maxes.riaa.number;
    let firstWeekSalesMax = temp.sort(
      (a, b) =>
        b.studioAlbumStats.maxes.firstWeekSales.number -
        a.studioAlbumStats.maxes.firstWeekSales.number
    )[0].studioAlbumStats.maxes.firstWeekSales.number;
    let streamsMax = temp.sort(
      (a, b) =>
        b.studioAlbumStats.maxes.firstWeekStreams.number -
        a.studioAlbumStats.maxes.firstWeekStreams.number
    )[0].studioAlbumStats.maxes.firstWeekStreams.number;

    const singleRiaaMax = getMaxTotalRiaaForSinglesYear(temp);

    return { riaaMax, firstWeekSalesMax, streamsMax, singleRiaaMax };
  };

  useEffect(() => {
    if (id) {
      dispatch(updateLoading(true));

      const artists = id.split("&");

      artists.forEach(async (artist) => {
        const { data } = await axios.get(API + `/artistDataByName/${artist}`);

        await dispatch(addArtistToCompare(data));
      });

      dispatch(updateLoading(false));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (comparedArtists.length) {
      setMaxData(getMaxes());
    }
  }, [comparedArtists]); // eslint-disable-line

  return (
    <>
      <Helmet>
        <title>Artist Charts</title>
        <meta
          name="description"
          content="Quickly and easily compare artist's career. Discover your favorite artists success based on our most valuable measurements and determine who is the greatest artists of all time with the numbers to back it all up."
        />
      </Helmet>
      {loading && <LoadingIndicator />}
      {!loading && Boolean(!comparedArtists.length) && <AddArtistsMessage />}
      {!loading && Boolean(comparedArtists.length) && (
        <div style={{ width: "100%", maxWidth: "1536px", alignSelf: "center" }}>
          <div
            className="sectionTitle"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 0,
              paddingTop: 0,
            }}
          >
            <div style={{ display: "flex", alignItems: "end" }}>
              Album Charts
            </div>
            <SharePopover customUrl={shareArtistString()} />
          </div>
          <Grid
            container
            justify="center"
            spacing={4}
            style={{
              width: "100%",
              margin: "auto",
            }}
          >
            {artistsWithFilteredSalesAndStreams.map((artist, index) => (
              <Grid
                style={{
                  padding: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                key={index}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <ChartCard artist={artist} maxData={maxData} type={"album"} />
              </Grid>
            ))}
          </Grid>
          <div className="sectionTitle">Single Charts</div>
          <Grid
            container
            justify="center"
            spacing={4}
            style={{
              width: "100%",
              margin: "auto",
            }}
          >
            {artistsWithSingles.map((artist, index) => (
              <Grid
                style={{
                  padding: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                key={index}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <ChartCard artist={artist} maxData={maxData} type={"single"} />
              </Grid>
            ))}
          </Grid>
          <Leaders comparedArtists={comparedArtists} />
        </div>
      )}
    </>
  );
}

function AddArtistsMessage() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "0 50px",
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Alert
        variant="filled"
        severity="info"
        style={{ width: "fit-content", background: "#047EFD" }}
      >
        <AlertTitle style={{ fontSize: "20px" }}>
          No artists selected:
        </AlertTitle>
        <div style={{ fontSize: "16px" }}>
          Please add artists in the charts tab to use the compare feature.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate("/Charts");
            }}
            style={{ background: "white", color: "black" }}
          >
            Charts
          </Button>
        </div>
      </Alert>
    </div>
  );
}

// This is gross... Can merge logic with SingleChart.js's getSinglesYearData logic
function getMaxTotalRiaaForSinglesYear(artists) {
  let max = 0;
  const singlesData = [];

  artists.forEach(({ singles }) => {
    const allYears = singles.map((single) => single?.release_date);
    const uniqueYears = [...new Set(allYears)];

    const singlesByYear = [];
    uniqueYears.forEach((year) => {
      const singlesInSameYear = singles.filter(
        (single) => single.release_date === year
      );

      singlesByYear.push(singlesInSameYear);
    });

    const yearTotalsArray = [];
    // get total of riaa for each year
    singlesByYear.forEach((singlesList) => {
      const riaaTotalForYear = singlesList.reduce(
        (partialSum, a) => partialSum + Number(a.riaa),
        0
      );

      if (riaaTotalForYear > max) max = riaaTotalForYear;
      yearTotalsArray.push(riaaTotalForYear);
    });

    singlesData.push({
      uniqueYears,
      singlesByYear,
      yearTotalsArray,
    });
  });
  // find a way to return [uniqueYears, singlesByYear, yearTotalsArray];
  // for each artists
  return max;
}
