export function getAlbumDates(projects, onlyStudioAlbums) {
  let projectsClone = [...projects];
  const dates = {};

  if (onlyStudioAlbums) {
    projectsClone.filter((project) => project.project_type === "Studio Album");

    for (let i = 0; i < projectsClone.length; i++) {
      const currentProject = projectsClone[i];

      if (currentProject.project_type === "Studio Album") {
        dates.firstAlbumYear = currentProject.release_date;
        break;
      }
    }

    for (let i = projectsClone.length - 1; i >= 0; i--) {
      const currentProject = projectsClone[i];

      if (currentProject.project_type === "Studio Album") {
        dates.lastAlbumYear = currentProject.release_date;
        break;
      }
    }
  } else {
    dates.firstAlbumYear = projects[0].release_date;
    dates.lastAlbumYear = projects[projects.length - 1].release_date;
  }

  return dates;
}

export function getSingleDates(singles) {
  let oldestYear = Infinity;
  let newestYear = 0;

  singles.forEach(({ release_date }) => {
    if (release_date < oldestYear) oldestYear = release_date;
    if (release_date > newestYear) newestYear = release_date;
  });
  return {
    firstSingleYear: oldestYear,
    lastSingleYear: newestYear,
  };
}
