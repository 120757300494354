import { useSelector } from "react-redux";
import { selectLoading, selectSearchArtists } from "../../redux";
import ChartCard from "./ChartCard";
import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import "./charts.css";

export default function ChartsList() {
  const loading = useSelector(selectLoading);
  const artists = useSelector(selectSearchArtists);

  return (
    <div className="chartsListContainer">
      <Helmet>
        <title>Artist Charts</title>
        <meta
          name="description"
          content="Search your favorite artist and visualize their career sales beautifully with charts that display RIAA Certification, First Week Sales, and First Week Streams."
        />
      </Helmet>
      <div className="sectionTitle">Artist Charts</div>
      <Grid
        container
        justify="center"
        spacing={4}
        style={{
          width: "100%",
          margin: "auto",
        }}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          artists.map((artist, index) => (
            <Grid
              style={{
                padding: 20,
                display: "flex",
                justifyContent: "center",
              }}
              item
              key={index}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={4}
            >
              <ChartCard artist={artist} />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
}
