import { createSlice } from "@reduxjs/toolkit";

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    artists: [],
    comparedArtists: [],
    genre: "Rap",
    loading: false,
    searchArtists: [],
  },
  reducers: {
    addArtistToCompare: (state, action) => {
      const compareArrayWithArtistAdded = [...state.comparedArtists];
      compareArrayWithArtistAdded.push(action.payload);
      compareArrayWithArtistAdded.sort(
        (a, b) =>
          b.studioAlbumStats.totals.riaa - a.studioAlbumStats.totals.riaa
      );
      state.comparedArtists = compareArrayWithArtistAdded;
    },

    removeArtistFromCompare: (state, action) => {
      const compareArrayWithArtistRemoved = state.comparedArtists.filter(
        (artist) => artist.name !== action.payload.name
      );

      compareArrayWithArtistRemoved.sort((a, b) => b.riaaTotal - a.riaaTotal);
      state.comparedArtists = compareArrayWithArtistRemoved;
    },

    updateArtists: (state, action) => {
      state.artists = action.payload;
    },

    updateGenre: (state, action) => {
      state.genre = action.payload;
    },

    updateLoading: (state, action) => {
      state.loading = action.payload;
    },

    updateSearchArtists: (state, action) => {
      state.searchArtists = action.payload;
    },
  },
});

export const {
  addArtistToCompare,
  removeArtistFromCompare,
  updateArtists,
  updateGenre,
  updateLoading,
  updateSearchArtists,
} = mainSlice.actions;

export const selectArtists = (state) => state.artists;

export const selectComparedArtists = (state) => state.comparedArtists;

export const selectLoading = (state) => state.loading;

export const selectGenre = (state) => state.genre;

export const selectSearchArtists = (state) => state.searchArtists;

export default mainSlice.reducer;
