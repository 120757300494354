import axios from "axios";
import { updateArtists, updateLoading, updateSearchArtists } from "../redux";
import { API } from "../utils/literals";

export async function getArtists(dispatch, genre) {
  try {
    dispatch(updateLoading(true));

    const { data: artistData } = await axios.get(
      API + `/artistDataByGenre/${genre}`
    );

    dispatch(updateArtists(artistData));
    dispatch(updateSearchArtists(artistData));
    dispatch(updateLoading(false));
  } catch (e) {
    console.log("ERROR", e);
  }
}

export async function getLandingPageArtists() {
  try {
    const { data } = await axios.get(API + `/landingPageArtistData`);

    return data;
  } catch (e) {
    console.log("ERROR", e);
  }
}
