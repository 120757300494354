import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AlbumIcon from "@mui/icons-material/Album";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";

export default function TopProjectCard({
  title,
  project,
  value,
  artist,
  stat,
}) {
  const formatter = new Intl.NumberFormat("en");

  return (
    <Grid
      style={{
        padding: 20,
        display: "flex",
        justifyContent: "center",
      }}
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={4}
    >
      <Card className="miniChartContainer">
        <CardContent>
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {project}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {value ? `By ${artist}` : "N/A"}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            {stat === "RIAA" && (
              <AlbumIcon style={{ fill: "#067efd", fontSize: "100" }} />
            )}
            {stat === "Sales" && (
              <MonetizationOnIcon
                style={{ fill: "#fcdd0a", fontSize: "100" }}
              />
            )}
            {stat === "Streams" && (
              <QueueMusicIcon style={{ fill: "#01cd46", fontSize: "100" }} />
            )}
          </div>

          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "22px",
            }}
            variant="h5"
            component="div"
          >
            {stat === "RIAA" && `${value}x Platinum`}
            {stat === "Sales" && `${formatter.format(value)} Units Sold`}
            {stat === "Streams" && `${formatter.format(value)} Streams`}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
