import { Bar } from "react-chartjs-2";
import { singleChartOptions } from "../../../utils/chartInfo";

export const Chart = ({ artist, displayLabels, maxRiaa }) => {
  const [yearsList, singlesByYear, yearTotalsArray] = getSinglesYearData(
    artist.singles
  );

  const tooltipLabels = {
    callbacks: {
      label: (context) => {
        return [
          `${context.parsed.y}x Platinum`,
          ...singlesByYear[context.dataIndex].map(
            ({ name, riaa }) => `${riaa}x: ${name}`
          ),
        ];
      },
    },
  };

  const options = singleChartOptions(artist, displayLabels, maxRiaa);
  options.plugins.tooltip = tooltipLabels;

  const data = {
    labels: yearsList,
    datasets: [
      {
        data: yearTotalsArray,
        backgroundColor: "#047EFD",
        borderColor: "#047EFD",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

function getSinglesYearData(singlesArray) {
  const allYears = singlesArray.map((single) => single?.release_date);
  const uniqueYears = [...new Set(allYears)];

  // separate singles in lists by year
  const singlesByYear = [];
  uniqueYears.forEach((year) => {
    const singlesInSameYear = singlesArray.filter(
      (single) => single.release_date === year
    );

    singlesByYear.push(singlesInSameYear);
  });

  const yearTotalsArray = [];
  // get total of riaa for each year
  singlesByYear.forEach((singlesList) => {
    const riaaTotalForYear = singlesList.reduce(
      (partialSum, a) => partialSum + Number(a.riaa),
      0
    );
    yearTotalsArray.push(riaaTotalForYear);
  });

  return [uniqueYears, singlesByYear, yearTotalsArray];
}
