import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectForm from "./ProjectForm";
import { API } from "../../utils/literals";

export default function SearchForm({ setMessage, queryType }) {
  const [searchName, setSearchName] = useState("");
  const [searchArtist, setSearchArtist] = useState("");
  const [results, setResults] = useState([]);
  const [albumToEdit, setAlbumToEdit] = useState({});

  function handleSearch() {
    axios
      .get(API + `/searchProjects`, {
        params: {
          name: searchName,
          artist: searchArtist
        }
      })
      .then((res) => setResults(res.data))
      .catch((e) => console.log(e));
  }

  return (
    <>
      <form>
        <div style={{ display: "flex", gap: "1rem" }}>
          <TextField
            label="Project Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            required
          />
          <TextField
            label="Artist"
            value={searchArtist}
            onChange={(e) => setSearchArtist(e.target.value)}
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </div>
      </form>
      {albumToEdit.name && (
        <ProjectForm
          queryType={queryType}
          setMessage={setMessage}
          albumToEdit={albumToEdit}
          handleSearch={handleSearch}
          setAlbumToEdit={setAlbumToEdit}
        />
      )}
      {results.length > 0 && (
        <DisplayProject
          results={results}
          setMessage={setMessage}
          handleSearch={handleSearch}
          setAlbumToEdit={setAlbumToEdit}
        />
      )}
    </>
  );
}

function DisplayProject({
  results,
  setMessage,
  handleSearch,
  setAlbumToEdit,
}) {
  function handleDelete(id) {
    axios
      .delete(API + `/projects/${id}`)
      .then((res) => {
        setMessage(res.data);
        handleSearch();
      })
      .catch((e) => console.log(e));
  }

  return (
    <TableContainer component={Paper} style={{ margin: "1rem 0" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Artist</TableCell>
            <TableCell align="left">RIAA</TableCell>
            <TableCell align="left">Sales</TableCell>
            <TableCell align="left">Streams</TableCell>
            <TableCell align="left">Genre</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Update</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((project) => (
            <TableRow
              key={project.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{project.id}</TableCell>
              <TableCell align="left">{project.name}</TableCell>
              <TableCell align="left">{project.artist}</TableCell>
              <TableCell align="left">{project.riaa}</TableCell>
              <TableCell align="left">{project.first_week_sales}</TableCell>
              <TableCell align="left">{project.streams}</TableCell>
              <TableCell align="left">{project.genre}</TableCell>
              <TableCell align="left">{project.release_date}</TableCell>
              <TableCell align="left">{project.project_type}</TableCell>
              <TableCell align="left">
                <button
                  onClick={() => {
                    setAlbumToEdit(project);
                  }}
                >
                  <EditIcon />
                </button>
              </TableCell>
              <TableCell align="left">
                <button onClick={() => handleDelete(project.id)}>
                  <DeleteIcon />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
