import { useEffect, useState } from "react";
import MetricsGuide from "./Metrics/Metrics";
import { getLandingPageArtists } from "../../utils/dataManipulation";
import ChartCard from "../Charts/ChartCard";
import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";
import { Grid } from "@mui/material";
import statement from "./NewLandingPageStatement.png";
import { Helmet } from "react-helmet-async";
import "./landingPage.css";

export default function LandingPage() {
  const [artists, setArtists] = useState([]);
  const areArtistsLoaded = Boolean(artists.length);

  async function fetchLandingPageArtists() {
    const artists = await getLandingPageArtists();

    setArtists(artists);
  }

  useEffect(() => {
    fetchLandingPageArtists();
  }, []);

  return (
    <div className="landingPageContainer">
      <Helmet>
        <title>Artist Charts</title>
        <meta
          name="description"
          content="Over 200 artist's album and singles sales at your fingertips. Compare artist's commercial success simply, beautifully, and for free."
        />
      </Helmet>
      <img
        className="openingStatement"
        src={statement}
        alt={"Music sales at your fingertips"}
      />
      {!areArtistsLoaded && <LoadingIndicator />}
      {areArtistsLoaded && (
        <Grid
          container
          spacing={2}
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {artists.map((artist, i) => (
            <Grid
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              key={i}
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
            >
              <ChartCard
                artist={artist}
                type={i % 2 === 0 ? "album" : "single"}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <MetricsGuide />
    </div>
  );
}
