import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MobileNav from "./MobileNav/MobileNav";
import SearchBar from "../SearchBar/SearchBar";
import Tabs from "./Tabs";
import "./NavBar.css";

export default function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      style={{
        background: "transparent",
      }}
      elevation={0}
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            style={{
              color: "#047EFD",
              fontWeight: 800,
              fontFamily: "Rubik, Roboto",
            }}
            className="vanzariButton"
            variant="h4"
            noWrap
            onClick={() => navigate("/")}
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            VANZARI
          </Typography>
          <Tabs handleCloseNavMenu={handleCloseNavMenu} />
          <Box
            className={location.pathname !== "/Charts" && "invisible"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <SearchBar />
          </Box>
          <MobileNav
            anchorElNav={anchorElNav}
            handleCloseNavMenu={handleCloseNavMenu}
            handleOpenNavMenu={handleOpenNavMenu}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
