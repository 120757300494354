import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopProjectCard from "./TopProjectCard";
import Box from "@mui/material/Box";
import "./leaderTable.css";

export default function Leaders({ comparedArtists }) {
  const [leaders, setLeaders] = useState({});

  const rows = comparedArtists.map((artist, index) => {
    return {
      ...artist,
      id: index,
    };
  });

  useEffect(() => {
    let highestRiaaTotal = 0;
    let highestSalesTotal = 0;
    let highestStreamsTotal = 0;
    let highestRiaaMax = 0;
    let highestSalesMax = 0;
    let highestStreamsMax = 0;

    const stats = {
      riaaTotal: {
        artist: "",
        number: 0,
      },
      salesTotal: {
        artist: "",
        number: 0,
      },
      streamsTotal: {
        artist: "",
        number: 0,
      },
      riaaMax: {
        artist: "",
        project: "",
        number: 0,
      },
      salesMax: {
        artist: "",
        project: "",
        number: 0,
      },
      streamsMax: {
        artist: "",
        project: "",
        number: 0,
      },
    };

    comparedArtists.forEach((artist) => {
      let riaaTotal = artist.allProjectStats.totals.riaa;
      let salesTotal = artist.allProjectStats.totals.firstWeekSales;
      let streamsTotal = artist.allProjectStats.totals.firstWeekStreams;
      let riaaMax = artist.allProjectStats.maxes.riaa;
      let salesMax = artist.allProjectStats.maxes.firstWeekSales;
      let streamsMax = artist.allProjectStats.maxes.firstWeekStreams;

      if (riaaTotal > highestRiaaTotal) {
        highestRiaaTotal = riaaTotal;
        stats.riaaTotal.number = riaaTotal;
        stats.riaaTotal.artist = artist.name;
      }

      if (salesTotal > highestSalesTotal) {
        highestSalesTotal = salesTotal;
        stats.salesTotal.number = salesTotal;
        stats.salesTotal.artist = artist.name;
      }

      if (streamsTotal > highestStreamsTotal) {
        highestStreamsTotal = streamsTotal;
        stats.streamsTotal.number = streamsTotal;
        stats.streamsTotal.artist = artist.name;
      }

      if (riaaMax.number > highestRiaaMax) {
        highestRiaaMax = riaaMax.number;
        stats.riaaMax.number = riaaMax.number;
        stats.riaaMax.artist = artist.name;
        stats.riaaMax.project = riaaMax.name;
      }

      if (salesMax.number > highestSalesMax) {
        highestSalesMax = salesMax.number;
        stats.salesMax.number = salesMax.number;
        stats.salesMax.artist = artist.name;
        stats.salesMax.project = salesMax.name;
      }

      if (streamsMax.number > highestStreamsMax) {
        highestStreamsMax = streamsMax.number;
        stats.streamsMax.number = streamsMax.number;
        stats.streamsMax.artist = artist.name;
        stats.streamsMax.project = streamsMax.name;
      }
    });

    setLeaders(stats);
  }, [comparedArtists]);

  const formatter = new Intl.NumberFormat("en", {
    notation: "compact",
  });

  const generateNumber = (num) => {
    return num === 0 ? "N/A" : formatter.format(num);
  };

  const { riaaTotal, salesTotal, streamsTotal, riaaMax, salesMax, streamsMax } =
    leaders;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="sectionTitle">Top Projects</div>
      {riaaMax && (
        <Grid
          container
          justify="space-between"
          spacing={4}
          style={{
            width: "100%",
            margin: "auto",
          }}
        >
          <TopProjectCard
            title="Highest RIAA Certification"
            project={riaaMax.project}
            value={riaaMax.number}
            artist={riaaMax.artist}
            stat={"RIAA"}
          />
          {Boolean(salesMax.number) && (
            <TopProjectCard
              title="Most First Week Sales"
              project={salesMax.project}
              value={salesMax.number}
              artist={salesMax.artist}
              stat={"Sales"}
            />
          )}
          {Boolean(streamsMax.number) && (
            <TopProjectCard
              title="Most First Week Streams"
              project={streamsMax.project}
              value={streamsMax.number}
              artist={streamsMax.artist}
              stat={"Streams"}
            />
          )}
        </Grid>
      )}
      <Box
        style={{
          width: "-webkit-fill-available",
        }}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <div className="sectionTitle">Career Leaderboard</div>
        <div
          style={{
            width: "-webkit-fill-available",
            padding: "20px",
          }}
        >
          {leaders?.riaaTotal && (
            <Paper>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="headerRow">
                      <TableCell>Artist</TableCell>
                      <TableCell align="center">RIAA</TableCell>
                      <TableCell align="center">First Week Sales</TableCell>
                      <TableCell align="center">First Week Streams</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      const { riaa, firstWeekSales, firstWeekStreams } =
                        row.allProjectStats.totals;

                      return (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="artistCell"
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={
                              riaaTotal.artist === row.name && "leadingCell"
                            }
                          >
                            {riaa}x Platinum
                          </TableCell>
                          <TableCell
                            align="center"
                            className={
                              salesTotal.artist === row.name && "leadingCell"
                            }
                          >
                            {generateNumber(firstWeekSales)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={
                              streamsTotal.artist === row.name && "leadingCell"
                            }
                          >
                            {generateNumber(firstWeekStreams)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </div>
      </Box>
    </div>
  );
}
