import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
// import ReactGA from "react-ga4";
import "./index.css";

// IDK if this work
// const trackingId = "G-JN2LV49QIN";
// ReactGA.initialize(trackingId);

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Provider>,
  document.getElementById("root")
);
