import React from "react";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import SearchBar from "../../SearchBar/SearchBar";
import { useSelector } from "react-redux";
import { selectComparedArtists } from "../../../redux";
import MobileNavMenu from "./MobileNavMenu";

const MobileNav = ({ anchorElNav, handleOpenNavMenu, handleCloseNavMenu }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const comparedArtists = useSelector(selectComparedArtists);
  const onChartsPage = location.pathname === "/Charts";

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "flex", md: "none" },
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon style={{ fill: "#047EFD" }} />
      </IconButton>
      <MobileNavMenu
        anchorElNav={anchorElNav}
        handleOpenNavMenu={handleOpenNavMenu}
        handleCloseNavMenu={handleCloseNavMenu}
      />
      <Box sx={{ display: { xs: onChartsPage ? "none" : "flex", md: "flex" } }}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          style={{
            color: "#047EFD",
            fontWeight: 800,
            fontFamily: "Rubik, Roboto",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => navigate("/")}
        >
          VANZARI
        </Typography>
      </Box>
      <Box sx={{ display: { xs: onChartsPage ? "flex" : "none", md: "flex" } }}>
        <SearchBar />
      </Box>
      <IconButton
        size="large"
        disabled={!comparedArtists.length}
        onClick={() => navigate("/CompareArtists")}
      >
        <Badge
          badgeContent={comparedArtists.length}
          classes={{ badge: classes.customBadge }}
        >
          <CompareArrowsIcon
            style={{ fill: comparedArtists.length ? "#047EFD" : "#B6B6B6" }}
          />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default MobileNav;

const useStyles = makeStyles({
  customBadge: {
    backgroundColor: "#047EFD",
    color: "white",
  },
});
