import { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import updateSearch from "../../utils/chartSearchFilter";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchArtists, selectArtists } from "../../redux";
import classNames from "classnames";
import styles from "./searchBar.module.css";

export default function SearchBar() {
  const [query, setQuery] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const dispatch = useDispatch();
  const artists = useSelector(selectArtists);
  const ref = useRef(null);

  useEffect(() => {
    dispatch(updateSearchArtists(updateSearch(artists, query)));
  }, [query]); // eslint-disable-line

  const focusInput = () => {
    setInputFocused(true);
    ref.current.focus();
  };

  const blurInput = () => {
    setInputFocused(false);
  };

  return (
    <div
      className={classNames(styles.searchContainer, {
        [styles.selected]: inputFocused,
      })}
      onClick={focusInput}
      onBlur={blurInput}
    >
      <SearchIcon style={{ color: "black" }} />
      <input
        className={styles.input}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Artists"
        ref={ref}
      />
    </div>
  );
}
