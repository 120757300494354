import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { selectComparedArtists, selectGenre, updateGenre } from "../../redux";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import "./NavBar.css";

export default function Tabs({ handleCloseNavMenu }) {
  const dispatch = useDispatch();
  const genre = useSelector(selectGenre);
  const [anchorEl, setAnchorEl] = useState(null);
  const comparedArtists = useSelector(selectComparedArtists);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (selectedGenre) => {
    if (selectedGenre) {
      if (genre !== selectedGenre) {
        dispatch(updateGenre(selectedGenre));
      }
      navigate("/Charts");
    }
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "none", md: "flex" },
        justifyContent: "center",
        gap: "2rem",
      }}
    >
      <Button
        className={location.pathname === "/" ? classes.currentTab : classes.tab}
        key={"Home"}
        onClick={() => {
          handleCloseNavMenu();
          navigate("/");
        }}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        Home
      </Button>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={
          location.pathname === "/Charts" ? classes.currentTab : classes.tab
        }
        onClick={handleClick}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        Charts
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("")}
      >
        <MenuItem
          disabled={location.pathname === "/Charts" && genre === "Rap"}
          onClick={() => handleClose("Rap")}
        >
          Rap
        </MenuItem>
        <MenuItem
          disabled={location.pathname === "/Charts" && genre === "Pop"}
          onClick={() => handleClose("Pop")}
        >
          Pop
        </MenuItem>
        <MenuItem
          disabled={location.pathname === "/Charts" && genre === "Rock"}
          onClick={() => handleClose("Rock")}
        >
          Rock
        </MenuItem>
        <MenuItem
          disabled={location.pathname === "/Charts" && genre === "Country"}
          onClick={() => handleClose("Country")}
        >
          Country
        </MenuItem>
      </Menu>
      <Button
        disabled={!comparedArtists.length}
        className={
          location.pathname === "/CompareArtists"
            ? classes.currentTab
            : classes.tab
        }
        key={"Compare"}
        onClick={() => {
          handleCloseNavMenu();
          navigate("/CompareArtists");
        }}
        sx={{ my: 2, color: "white", display: "block" }}
      >
        <Badge
          badgeContent={comparedArtists.length}
          classes={{ badge: classes.customBadge }}
        >
          <span style={{ marginRight: 5 }}>Compare</span>
        </Badge>
      </Button>
    </Box>
  );
}

const useStyles = makeStyles({
  tab: {
    display: "flex",
    justifyContent: "center",
    color: "#424242",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
    },
    fontFamily: "Rubik",
    fontWeight: 500,
  },
  currentTab: {
    display: "flex",
    justifyContent: "center",
    color: "#424242",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
    },
    fontFamily: "Rubik",
    fontWeight: 500,
    border: "1px solid black",
  },
  customBadge: {
    backgroundColor: "#047EFD",
    color: "white",
  },
});
