import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import InvalidUrl from "./shared/InvalidUrl/InvalidUrl";
import LandingPage from "./components/LandingPage/LandingPage";
import UpdateDatabase from "./components/DatabaseQueryTool/UpdateDatabase";
import { useDispatch } from "react-redux";
import { getArtists } from "./utils/dataManipulation";
import { useSelector } from "react-redux";
import { selectGenre } from "./redux";
import Footer from "./components/Footer/Footer";
import ContactPage from "./components/Footer/Pages/ContactPage/ContactPage";
import PrivacyPolicy from "./components/Footer/Pages/PrivacyPolicy/PrivacyPolicy";
import ComparedArtists from "./components/ComparedArtists/ComparedArtists";
import ChartsList from "./components/Charts/ChartsList";
import "./App.css";

export default function App() {
  const genre = useSelector(selectGenre);
  const dispatch = useDispatch();

  useEffect(() => {
    getArtists(dispatch, genre);
  }, [genre]); // eslint-disable-line

  return (
    <div className="main">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Charts" element={<ChartsList />} />
          <Route path="/CompareArtists" element={<ComparedArtists />} />
          <Route
            path="/SharedArtistComparison/:id"
            element={<ComparedArtists />}
          />
          <Route path="/Danny" element={<UpdateDatabase />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/Privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<InvalidUrl />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
