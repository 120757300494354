import React, { useState, useEffect } from "react";
import "./styles.css";
import {
  TextField,
  Card,
  Typography,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
import AWS from "aws-sdk";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageSent, setIsMessageSent] = useState(false);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: "us-west-1",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      Destination: {
        ToAddresses: ["danielegarcia97@aol.com"],
      },
      Message: {
        Body: {
          Text: {
            Data: `From ${name}, \n\n\t${message} \n\nEmail: ${email}`,
          },
        },
        Subject: {
          Data: `New Message from ${name} via vanzari.com`,
        },
      },
      Source: "danielegarcia97@aol.com",
    };

    const sendPromise = new AWS.SES().sendEmail(params).promise();

    sendPromise
      .then((data) => {
        setName("");
        setEmail("");
        setMessage("");
        setIsMessageSent(true);
      })
      .catch((err) => {
        console.error(err, err.stack);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      <Card className="contactForm">
        <Typography
          variant="h3"
          style={{
            fontSize: "calc(100% + .6vw)",
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          Contact Us
        </Typography>
        {isMessageSent && (
          <>
            <Alert style={{ margin: '1rem 0' }} severity="success">
              <AlertTitle style={{ fontSize: 20 }}>
                Your message has been sent!
              </AlertTitle>
              <strong>Thank you!</strong> We will get back to you as soon as
              possible.
            </Alert>
          </>
        )}
        <form onSubmit={handleSubmit} className="form">
          <TextField
            required
            name="name"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            required
            name="email"
            label="Email"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            multiline
            rows={4}
            value={message}
            label="Message"
            variant="outlined"
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button variant="contained" className="btn" type="submit">
            Send
          </Button>
        </form>
      </Card>
    </div>
  );
}
