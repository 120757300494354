import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import axios from "axios";
import React, { useState } from "react";
import { API } from "../../utils/literals";
import { SingleForm } from "./SingleForm";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

export const SearchSinglesForm = ({ queryType, setMessage }) => {
  const [searchName, setSearchName] = useState("");
  const [searchArtist, setSearchArtist] = useState("");
  const [results, setResults] = useState([]);
  const [singleToEdit, setSingleToEdit] = useState({});

  function handleSearch() {
    axios
      .get(API + `/searchSingles`, {
        params: {
          name: searchName,
          artist: searchArtist,
        },
      })
      .then((res) => setResults(res.data))
      .catch((e) => console.log(e));
  }

  return (
    <>
      <form>
        <div style={{ display: "flex", gap: "1rem" }}>
          <TextField
            label="Single Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            required
          />
          <TextField
            label="Artist"
            value={searchArtist}
            onChange={(e) => setSearchArtist(e.target.value)}
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </div>
      </form>
      {singleToEdit.name && (
        <SingleForm
          queryType={queryType}
          setMessage={setMessage}
          singleToEdit={singleToEdit}
          handleSearch={handleSearch}
          setSingleToEdit={setSingleToEdit}
        />
      )}
      {results.length > 0 && (
        <DisplaySingles
          results={results}
          setMessage={setMessage}
          handleSearch={handleSearch}
          setSingleToEdit={setSingleToEdit}
        />
      )}
    </>
  );
};

function DisplaySingles({ results, setMessage, handleSearch, setSingleToEdit }) {
  function handleDelete(id) {
    axios
      .delete(API + `/Singles/${id}`)
      .then((res) => {
        setMessage(res.data);
        handleSearch();
      })
      .catch((e) => console.log(e));
  }

  return (
    <TableContainer component={Paper} style={{ margin: "1rem 0" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Artist</TableCell>
            <TableCell align="left">RIAA</TableCell>
            <TableCell align="left">Genre</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Update</TableCell>
            <TableCell align="left">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((single) => (
            <TableRow
              key={single.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{single.id}</TableCell>
              <TableCell align="left">{single.name}</TableCell>
              <TableCell align="left">{single.artist}</TableCell>
              <TableCell align="left">{single.riaa}</TableCell>
              <TableCell align="left">{single.genre}</TableCell>
              <TableCell align="left">{single.release_date}</TableCell>
              <TableCell align="left">{single.single_type}</TableCell>
              <TableCell align="left">
                <button
                  onClick={() => {
                    setSingleToEdit(single);
                  }}
                >
                  <EditIcon />
                </button>
              </TableCell>
              <TableCell align="left">
                <button onClick={() => handleDelete(single.id)}>
                  <DeleteIcon />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
