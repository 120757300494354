import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import {
  selectComparedArtists,
  addArtistToCompare,
  removeArtistFromCompare,
} from "../../../redux";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import AlbumIcon from "@mui/icons-material/Album";
import Filter1Icon from "@mui/icons-material/Filter1";
import Box from "@mui/material/Box";
import "../charts.css";

export const HeaderIcons = ({
  artist,
  setLargeChartIsOpen,
  displayProjectsChart,
  setDisplayProjectsChart,
  hideSingleAlbumToggle,
}) => {
  const dispatch = useDispatch();
  const comparedArtists = useSelector(selectComparedArtists);
  const displaySingleAlbumToggle =
    Boolean(artist.singles.length) && !hideSingleAlbumToggle;
  const isBeingCompared = Boolean(
    comparedArtists.find(({ name }) => name === artist.name)
  );

  const addArtist = async (artistToAdd) => {
    await dispatch(addArtistToCompare(artistToAdd));
  };

  const removeArtist = async (artistToRemove) => {
    await dispatch(removeArtistFromCompare(artistToRemove));
  };

  const CompareButton = () => (
    <>
      <Button
        className={"icons"}
        onClick={() =>
          isBeingCompared ? removeArtist(artist) : addArtist(artist)
        }
      >
        {isBeingCompared ? <RemoveIcon /> : <AddIcon />}
      </Button>
    </>
  );

  const AlbumsToSinglesButton = () => (
    <>
      {!displayProjectsChart ? (
        <Button
          onClick={() => setDisplayProjectsChart((prev) => !prev)}
          className="icons"
        >
          <AlbumIcon />
        </Button>
      ) : (
        <Button
          onClick={() => setDisplayProjectsChart((prev) => !prev)}
          className="icons"
        >
          <Filter1Icon />
        </Button>
      )}
    </>
  );

  return (
    <div className="svgWrapper">
      <CompareButton />
      <div className="artistName">{artist.name}</div>
      <div className="rightButtons">
        {displaySingleAlbumToggle && <AlbumsToSinglesButton />}
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Button onClick={setLargeChartIsOpen} className="icons">
            <AspectRatioIcon />
          </Button>
        </Box>
      </div>
    </div>
  );
};
