import React from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectGenre, updateGenre } from "../../../redux";
import { useDispatch } from "react-redux";

const MobileNavMenu = ({
  anchorElNav,
  handleOpenNavMenu,
  handleCloseNavMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const genre = useSelector(selectGenre);

  const handleChartsNavigate = (selectedGenre) => {
    dispatch(updateGenre(selectedGenre));
    navigate("/Charts");
    handleCloseNavMenu();
  };

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
      <MenuItem
        onClick={() => {
          handleCloseNavMenu();
          navigate("/");
        }}
      >
        <Typography textAlign="center">Home</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleChartsNavigate("Rap");
        }}
        disabled={location.pathname === "/Charts" && genre === "Rap"}
      >
        <Typography textAlign="center">Rap Charts</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleChartsNavigate("Pop");
        }}
        disabled={location.pathname === "/Charts" && genre === "Pop"}
      >
        <Typography textAlign="center">Pop Charts</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleChartsNavigate("Rock");
        }}
        disabled={location.pathname === "/Charts" && genre === "Rock"}
      >
        <Typography textAlign="center">Rock Charts</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleChartsNavigate("Country");
        }}
        disabled={location.pathname === "/Charts" && genre === "Country"}
      >
        <Typography textAlign="center">Country Charts</Typography>
      </MenuItem>
    </Menu>
  );
};

export default MobileNavMenu;
