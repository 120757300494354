import React from "react";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import "./footer.css";
export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footerContainer">
      <div className="linksContainer">
        <Link onClick={() => navigate("/Privacy")} className="links">
          Privacy Policy
        </Link>
        <Link onClick={() => navigate("/Contact")} className="links">
          Contact Us
        </Link>
      </div>
    </div>
  );
}
