import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { getChartData, largeChartOptions } from "../../../utils/chartInfo";
import { Chart as SinglesChart } from "../SmallSingleChart/Chart";

const LargeChart = ({ artist, chartType }) => {
  const [projectLabels, setProjectLabels] = useState({ label: [] });
  const [riaaData, setRiaaData] = useState({ data: [] });
  const [firstWeekSalesData, setFirstWeekSalesData] = useState({ data: [] });
  const [streamsData, setStreamsData] = useState({ data: [] });

  useEffect(() => {
    const labelsData = [];
    const riaa = [];
    const firstWeekSales = [];
    const streams = [];

    let projects = artist.projects;

    if (chartType === "Studio Albums") {
      projects = artist.projects.filter(
        ({ project_type }) => project_type === "Studio Album"
      );
    }

    projects.forEach((v) => {
      let name = v.name;

      if (name.length > 15) {
        name = name.slice(0, 15) + "...";
      }

      labelsData.push(`${name} (${v.release_date})`);
      riaa.push(v.riaa ? v.riaa : 0);
      firstWeekSales.push(v.first_week_sales > 0 ? v.first_week_sales : null);
      streams.push(v.streams > 0 ? v.streams : null);
    });

    setProjectLabels({
      label: labelsData,
    });

    setRiaaData({
      data: riaa,
    });

    setFirstWeekSalesData({
      data: firstWeekSales,
    });

    setStreamsData({
      data: streams,
    });
  }, [artist, chartType]);

  return (
    <>
      {chartType !== "Singles" && (
        <Line
          data={getChartData(
            projectLabels,
            riaaData,
            firstWeekSalesData,
            streamsData
          )}
          options={largeChartOptions(artist)}
        />
      )}
      {chartType === "Singles" && (
        <SinglesChart artist={artist} displayLabels={true} />
      )}
    </>
  );
};

export default LargeChart;
