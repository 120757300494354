import { CloseModal } from "../../CloseModal/CloseModal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const LargeHeader = ({
  artist,
  closeModal,
  chartType,
  setChartType,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0 2rem",
      }}
    >
      <FormControl size="small" style={{ minWidth: "175px" }}>
        <InputLabel id="demo-simple-select-label">Chart Type</InputLabel>
        <Select
          style={{ padding: 0 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={chartType}
          label="Chart Type"
          onChange={(e) => {
            setChartType(e.target.value);
          }}
        >
          <MenuItem value={"All Projects"}>All Projects</MenuItem>
          <MenuItem value={"Studio Albums"}>Studio Albums</MenuItem>
          {Boolean(artist.singles.length) && (
            <MenuItem value={"Singles"}>Singles</MenuItem>
          )}
        </Select>
      </FormControl>
      <div style={{ fontSize: "26px", fontWeight: "bold", color: "#424242" }}>
        {artist.name}
      </div>
      <CloseModal closeModal={closeModal} />
    </div>
  );
};
