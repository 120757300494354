import React from "react";
import { Grid } from "react-loader-spinner";
import "./styles.css";

const LoadingIndicator = () => {
  return (
    <div className="loading">
      <Grid heigth="150" width="150" color="#0057F2" ariaLabel="loading" />
    </div>
  );
};

export default LoadingIndicator;
