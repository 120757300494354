import { Box } from "@mui/system";
import React from "react";
import "./metrics.css";
import {
  riaaSideBySide,
  salesSideBySide,
  streamsSideBySide,
  riaaStack,
  salesStack,
  streamsStack,
} from "./metricUtils";

const MetricGuide = () => {
  const SideBySideMetrics = () => (
    <>
      {riaaSideBySide}
      {salesSideBySide}
      {streamsSideBySide}
    </>
  );

  const StackedMetrics = () => (
    <>
      {riaaStack}
      {salesStack}
      {streamsStack}
    </>
  );

  return (
    <div>
      <Box sx={{mt: 2, display: { xs: "block", md: "none" } }}>
        <StackedMetrics />
      </Box>
      <Box sx={{mt: 2, display: { xs: "none", md: "block" } }}>
        <SideBySideMetrics />
      </Box>
    </div>
  );
};

export default MetricGuide;
