import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import Share from "./ShareLinks";
import { shareIcon } from "../../utils/svgs";
import { makeStyles } from "@mui/styles";

const SharePopover = ({ customUrl }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const useStyles = makeStyles({
    button: {
      display: "flex",
      justifyContent: "center",
      color: "black",
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#3c52b2",
      },
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Button
        className={classes.button}
        variant="contained"
        {...bindTrigger(popupState)}
      >
        <span className="shareIcon">Share {shareIcon}</span>
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography>
          <div
            style={{ padding: 30, display: "flex", justifyContent: "center" }}
          >
            <Share customUrl={customUrl} />
          </div>
        </Typography>
      </Popover>
    </div>
  );
};

export default SharePopover;
