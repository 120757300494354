import Card from "@mui/material/Card";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Alert from "@mui/material/Alert";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import ProjectForm from "./ProjectForm";
import SearchForm from "./SearchForm";
import { SingleForm } from "./SingleForm";
import { SearchSinglesForm } from "./SearchSinglesForm";

export default function FormPropsTextFields() {
  const [message, setMessage] = useState("");
  const [queryType, setQueryType] = useState("Create");
  const [password, setPassword] = useState("");
  const correctPassword = "Vanzari2022";
  const location = window.location;
  const development =
    location.hostname === "localhost" || location.hostname === "127.0.0.1";

  function DisplayMessage() {
    let status = message.slice(0, 5) === "error" ? "error" : "success";

    return (
      <Alert severity={status} style={{ marginTop: "1rem" }}>
        {message}
      </Alert>
    );
  }

  const displayDatabaseQueryTool = () => (
    <>
      <Typography variant="h4">Database Query</Typography>
      <FormLabel id="query-type-label">Query Type</FormLabel>
      <RadioGroup
        aria-labelledby="query-type-label"
        defaultValue="Create Album"
        name="radio-buttons-group"
      >
        <div style={{ display: "flex" }}>
          <FormControlLabel
            value="Create Album"
            control={<Radio />}
            label="Create Album"
            onChange={(e) => setQueryType(e.target.value)}
          />
          <FormControlLabel
            value="Update/Delete Album"
            control={<Radio />}
            label="Update/Delete Album"
            onChange={(e) => setQueryType(e.target.value)}
          />
          <FormControlLabel
            value="Create Single"
            control={<Radio />}
            label="Create Single"
            onChange={(e) => setQueryType(e.target.value)}
          />
          <FormControlLabel
            value="Update/Delete Single"
            control={<Radio />}
            label="Update/Delete Single"
            onChange={(e) => setQueryType(e.target.value)}
          />
        </div>
      </RadioGroup>

      {queryType === "Create Album" && (
        <ProjectForm queryType={queryType} setMessage={setMessage} />
      )}
      {queryType === "Update/Delete Album" && (
        <SearchForm queryType={queryType} setMessage={setMessage} />
      )}
      {queryType === "Create Single" && (
        <SingleForm queryType={queryType} setMessage={setMessage} />
      )}
      {queryType === "Update/Delete Single" && (
        <SearchSinglesForm queryType={queryType} setMessage={setMessage} />
      )}

      {message.length > 0 && <DisplayMessage />}
    </>
  );

  const passwordCheck = () => (
    <>
      <Typography variant="h4" style={{ margin: "1rem 0" }}>
        Enter password
      </Typography>
      <TextField
        type="password"
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </>
  );

  return (
    <Card
      style={{
        margin: "3rem auto",
        padding: "1rem",
        width: "90%",
        minWidth: "400px",
        maxWidth: "1400px",
      }}
    >
      {development || password === correctPassword
        ? displayDatabaseQueryTool()
        : passwordCheck()}
    </Card>
  );
}
