import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import LargeChart from "./LargeChart";
import { LargeHeader } from "./LargeHeader";

export const LargeChartModal = ({ data }) => {
  const [chartType, setChartType] = useState("All Projects");
  const { setLargeChartIsOpen, largeChartIsOpen, artist } = data;

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    margin: "auto",
    width: "90%",
    maxWidth: "1200px",
    bgcolor: "background.paper",
    backgroundColor: "white",
    border: "2px solid lightgrey",
    outline: "none",
    borderRadius: 8,
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 2,
  };

  return (
    <>
      {largeChartIsOpen && (
        <Modal
          onClose={() => setLargeChartIsOpen(false)}
          open={largeChartIsOpen}
        >
          <Box style={{ ...boxStyle }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <LargeHeader
                artist={artist}
                closeModal={() => setLargeChartIsOpen(false)}
                chartType={chartType}
                setChartType={setChartType}
              />
              <LargeChart
                artist={artist}
                chartType={chartType}
                setChartType={setChartType}
              />
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};
