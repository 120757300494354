import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import axios from "axios";
import { API } from "../../utils/literals";

export default function ProjectForm({
  queryType,
  setMessage,
  albumToEdit,
  setAlbumToEdit,
  handleSearch,
}) {
  const [projectName, setProjectName] = useState(albumToEdit?.name ?? "");
  const [artist, setArtist] = useState(albumToEdit?.artist ?? "");
  const [riaa, setRiaa] = useState(albumToEdit?.riaa ?? 0);
  const [firstWeekSales, setfirstWeekSales] = useState(
    albumToEdit?.first_week_sales ?? 0
  );
  const [streams, setStreams] = useState(albumToEdit?.first_week_streams ?? 0);
  const [releaseDate, setReleaseDate] = useState(albumToEdit?.release_date ?? 0);
  const [genre, setGenre] = useState(albumToEdit?.genre ?? "");
  const [projectType, setProjectType] = useState(albumToEdit?.project_type ?? "");

  const buttonName =
    queryType === "Create Album" ? "Add Album" : "Update Album";

  function handleSubmit(e) {
    e.preventDefault();

    const body = {
      name: projectName,
      artist,
      riaa,
      first_week_sales: firstWeekSales,
      streams,
      release_date: releaseDate,
      genre,
      project_type: projectType,
    };

    if (queryType === "Create Album") {
      axios
        .post(API + "/projects", body)
        .then((res) => {
          setMessage(res.data);
          setAlbumToEdit({});
          handleSearch();
        })
        .catch((e) => console.log(e));
    } else {
      axios
        .put(API + `/projects/${albumToEdit?.id}`, body)
        .then((res) => {
          setMessage(res.data);
        })
        .catch((e) => console.log(e));
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ margin: "1rem 0" }}>
      <div style={{ display: "flex", gap: "1rem" }}>
        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          required
        />
        <TextField
          label="Artist"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
          required
        />
        <TextField
          label="RIAA"
          value={riaa}
          type="number"
          onChange={(e) => setRiaa(e.target.value)}
        />
        <TextField
          label="First Week Sales"
          type="number"
          value={firstWeekSales}
          onChange={(e) => setfirstWeekSales(e.target.value)}
        />
        <TextField
          label="First Week Streams"
          type="number"
          value={streams}
          onChange={(e) => setStreams(e.target.value)}
        />
        <TextField
          label="Release Date"
          type="number"
          value={releaseDate}
          onChange={(e) => setReleaseDate(e.target.value)}
          required
        />
        <TextField
          label="Genre"
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          required
        />
        <TextField
          label="Project Type"
          value={projectType}
          onChange={(e) => setProjectType(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button variant="contained" type="submit">
          {buttonName}
        </Button>
      </div>
    </form>
  );
}
