import { Typography } from "@mui/material";
import AlbumIcon from "@mui/icons-material/Album";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";

import riaaImage from "./riaa.png";
import salesImage from "./sales.png";
import streamsImage from "./streams.png";

const metricItems = [
  {
    id: "1",
    title: "RIAA Certification",
    description:
      "The Recording Industry Association of America awards albums that sell many copies. Gold: 500,000 units / 0.5x platinum. Platinum: 1,000,000 units / 1x platinum. Diamond: 10,000,000 units / 10x platinum",
    color: "#067efd",
  },
  {
    id: "2",
    title: "First Week Sales",
    description:
      "Recorded by Nielsen SoundScan since and 1991 determines Billboard’s U.S. charts. Great predictors of long-term success. Made up of album sales, downloads, & streams (Album Equivalent Units).",
    color: "#fcdd0a",
  },
  {
    id: "3",
    title: "First Week Streams",
    description:
      "Aggregative of Spotify, Apple Music, Amazon Music, etc. A portion of first week sales: 1,500 steams = 1 album unit",
    color: "#01cd46",
  },
];

const [riaa, sales, streams] = metricItems;

export const riaaSideBySide = (
  <section className="legendItem">
    <div className="content">
      <Typography variant="h5" style={{ fontWeight: "500", fontSize: "26px" }}>
        {riaa.title}
      </Typography>
      <span>{riaa.description}</span>
    </div>
    <div className="content">
      <AlbumIcon style={{ fill: riaa.color }} />
    </div>
  </section>
);

export const salesSideBySide = (
  <section className="legendItem">
    <div className="content">
      <MonetizationOnIcon style={{ fill: sales.color }} />
    </div>
    <div className="content">
      <Typography variant="h5" style={{ fontWeight: "500", fontSize: "26px" }}>
        {sales.title}
      </Typography>
      <span>{sales.description}</span>
    </div>
  </section>
);

export const streamsSideBySide = (
  <section className="legendItem">
    <div className="content">
      <Typography variant="h5" style={{ fontWeight: "500", fontSize: "26px" }}>
        {streams.title}
      </Typography>
      <span>{streams.description}</span>
    </div>
    <div className="content">
      <QueueMusicIcon style={{ fill: streams.color }} />
    </div>
  </section>
);

export const riaaStack = (
  <section
    className="legendItem"
    style={{ display: "flex", flexDirection: "column" }}
  >
    <div style={{ height: "150px", marginBottom: "2rem" }}>
      <img className="image" src={riaaImage} alt="riaa icon" />
    </div>
    <div className="content" style={{ width: "100%" }}>
      <Typography variant="h5" style={{ fontWeight: "500", fontSize: "26px" }}>
        {riaa.title}
      </Typography>
      <span>{riaa.description}</span>
    </div>
  </section>
);

export const salesStack = (
  <section
    className="legendItem"
    style={{ display: "flex", flexDirection: "column" }}
  >
    <div style={{ height: "150px", marginBottom: "2rem" }}>
      <img className="image" src={salesImage} alt="riaa icon" />
    </div>
    <div className="content topMargin" style={{ width: "100%" }}>
      <Typography variant="h5" style={{ fontWeight: "500", fontSize: "26px" }}>
        {sales.title}
      </Typography>
      <span>{sales.description}</span>
    </div>
  </section>
);

export const streamsStack = (
  <section
    className="legendItem"
    style={{ display: "flex", flexDirection: "column" }}
  >
    <img src={streamsImage} height="125px" alt="streams icon" />
    <div className="content topMargin" style={{ width: "100%" }}>
      <Typography variant="h5" style={{ fontWeight: "500", fontSize: "26px" }}>
        {streams.title}
      </Typography>
      <span>{streams.description}</span>
    </div>
  </section>
);
