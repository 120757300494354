import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { API } from "../../utils/literals";

export const SingleForm = ({
  queryType,
  setMessage,
  singleToEdit,
  handleSearch,
  setSingleToEdit,
}) => {
  const [name, setName] = useState(singleToEdit?.name ?? "");
  const [artist, setArtist] = useState(singleToEdit?.artist ?? "");
  const [riaa, setRiaa] = useState(singleToEdit?.riaa ?? 0);
  const [releaseDate, setReleaseDate] = useState(singleToEdit?.release_date ?? 0);
  const [genre, setGenre] = useState(singleToEdit?.genre ?? "");
  const [singleType, setSingleType] = useState(singleToEdit?.single_type ?? "");

  const buttonName =
    queryType === "Create Single" ? "Add Single" : "Update Single";

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      name,
      artist,
      riaa,
      release_date: releaseDate,
      genre,
      single_type: singleType,
    };

    if (queryType === "Create Single") {
      console.log("create");
      axios
        .post(API + "/singles", body)
        .then((res) => setMessage(res.data))
        .catch((e) => console.log(e));
    } else {
      console.log("edit", singleToEdit);
      axios
        .put(API + `/singles/${singleToEdit?.id}`, body)
        .then((res) => {
          setMessage(res.data);
          setSingleToEdit({});
          handleSearch();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ margin: "1rem 0" }}>
      <div style={{ display: "flex", gap: "1rem" }}>
        <TextField
          label="Project Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Artist"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
          required
        />
        <TextField
          label="RIAA"
          value={riaa}
          type="number"
          onChange={(e) => setRiaa(e.target.value)}
        />
        <TextField
          label="Release Date"
          type="number"
          value={releaseDate}
          onChange={(e) => setReleaseDate(e.target.value)}
          required
        />
        <TextField
          label="Genre"
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          required
        />
        <TextField
          label="Single Type"
          value={singleType}
          onChange={(e) => setSingleType(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button variant="contained" type="submit">
          {buttonName}
        </Button>
      </div>
    </form>
  );
};
