import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  getChartData,
  miniChartOptions,
  miniCompareChartOptions,
} from "../../../utils/chartInfo";
import "../charts.css";
import "chart.js/auto";

export default function SmallChart({ artist, maxData }) {
  const [projectLabels, setProjectLabels] = useState({ label: [] });
  const [riaaData, setRiaaData] = useState({ data: [] });
  const [firstWeekSalesData, setFirstWeekSalesData] = useState({ data: [] });
  const [streamsData, setStreamsData] = useState({ data: [] });

  useEffect(() => {
    if (artist.projects.length > 0) {
      setChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artist]);

  const setChartData = () => {
    const labelsData = [];
    const riaa = [];
    const firstWeekSales = [];
    const streams = [];

    artist.projects.forEach((v) => {
      if (v.project_type === "Studio Album") {
        labelsData.push(`${v.name} (${v.release_date})`);
        riaa.push(v.riaa ? v.riaa : 0);
        firstWeekSales.push(v.first_week_sales > 0 ? v.first_week_sales : null);
        streams.push(v.streams > 0 ? v.streams : null);
      }
    });

    setProjectLabels({
      label: labelsData,
    });

    setRiaaData({
      data: riaa,
    });

    setFirstWeekSalesData({
      data: firstWeekSales,
    });

    setStreamsData({
      data: streams,
    });
  };

  return (
    <div className="outerContainer">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#666",
        }}
        className="canvasContainer"
      >
        <Line
          data={getChartData(
            projectLabels,
            riaaData,
            firstWeekSalesData,
            streamsData
          )}
          options={
            maxData
              ? miniCompareChartOptions(artist, maxData)
              : miniChartOptions(artist)
          }
        />
      </div>
    </div>
  );
}
